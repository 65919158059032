import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useAuth } from "../../context/auth.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../components/customButton/CustomButton.js";

import config from "../../config.js";

import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import "./Login.css";

const Login = () => {
  // context
  const [auth, setAuth] = useAuth();
  // state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [visible, setVisible] = useState(false);

  const [quote, setQuote] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  // hooks
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();

  // const queryParams = new URLSearchParams(location.search);
  // const fromAction = queryParams.get("fromAction"); // 'like'

  //   const scrollWithOffset = (el) => {
  //     const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  //     const yOffset = -200;
  //     window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  //   };

  const quotes = [
    "Today is a new day. It's your day. You shape it. Sign in to start seeing available properties.",
    "The best time to buy a home is always five years ago - Ray Brown",
    "Real estate is the purest form of entrepreneurship – Brian Buffini",
  ];

  const backgroundImages = [
    <img
      src="./sell-menu-image.jpg"
      alt="Placeholder"
      className=" image-display"
    />,
    <img
      src="./onboradingImage1.png"
      alt="Placeholder"
      className="image-display"
    />,
    <img
      src="./onboradingImage2.png"
      alt="Placeholder"
      className="image-display"
    />,
    <img
      src="./onboradingImage3.png"
      alt="Placeholder"
      className="image-display"
    />,
  ];

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    // window.scrollTo(0, 0);

    const randomQuotes = Math.floor(Math.random() * quotes.length);
    setQuote(quotes[randomQuotes]);

    const randomImage = Math.floor(Math.random() * backgroundImages.length);
    setBackgroundImage(backgroundImages[randomImage]);

    const handleBackButton = () => {
      // Navigate back to the previous page
      window.history.back();
    };

    // Add an event listener for the popstate event (triggered by the browser back button)
    window.addEventListener("popstate", handleBackButton);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  const toggle = () => {
    setVisible(!visible);
  };

  const fetchUserWishlists = async (user) => {
    const { userId } = user;
    // console.log("userId", userId);
    try {
      const { data } = await axios.get(`/wishlist/${userId}`);

      // console.log("data=>", data);
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleFaceBookSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${config.AUTH_API}/user/facebook-signIn`
      );
      if (data?.success) {
        window.location.replace(data.responsePayload);
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      toast.error("Something went wrong", err);
    }
  };

  const handleGoogleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(`${config.AUTH_API}/user/google-signIn`);
      window.location.replace(data);
    } catch (err) {
      toast.error("Something went wrong", err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.post(`${config.AUTH_API}/user/SignIn`, {
        email,
        password,
        phoneNumber: "",
      });

      if (!data?.success) {
        setLoading(false);
        if (data?.statusCode === 417) {
          navigate("/confirmation");
        }
      } else {
        const { token, user } = data.responsePayload;
        const wishlistData = await fetchUserWishlists(user);
        const { wishlist } = wishlistData;
        const userWishlist = wishlist[0]?.wishlist;
        setAuth({ token, user, wishlist: userWishlist });
        //console.log("user", user);
        localStorage.setItem(
          "auth",
          JSON.stringify({ token, user: user, wishlist: userWishlist })
        );
        localStorage.removeItem("confirmation");
        toast.success("Login successful");
        setLoading(false);

        if (auth.user?.firstName === "") navigate("/user/profile");

        if (
          state?.fromAction === "like" ||
          location?.state === null ||
          location?.state === undefined
        ) {
          navigate("/");
        } else {
          navigate(location?.state);
        }

        // state?.fromAction === "like"
        //   ? navigate("/")
        //   : location?.state !== null
        //   ? navigate(location.state)
        //   : navigate("/");
      }
    } catch (err) {
      if (err.response.data.success === false) {
        switch (err.response.data.statusCode) {
          case 302: //user does not exist
            toast.error(err.response.data.message);
            navigate("/register");
            break;
          case 401:
            toast.error("Invalid Credential."); // Incorrect password
            break;
          case 417:
            toast.error("Email not confirmed."); // Incorrect password
            localStorage.setItem(
              "confirmation",
              JSON.stringify({
                email,
              })
            );
            navigate("/confirmation");
            // toast.error(err.response.data.message); //wrong password
            break;
          default:
          // code block"
        }
        // toast.error(err.response.data.message);
      } else toast.error("Something went wrong", err);
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-details">
        <div className="login-content">
          <h3>Welcome Back 👋</h3>
          <p className="login-word">{quote}</p>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="login-input">
              <label>Email</label>
              <input
                type="email"
                placeholder="example@email.com"
                className="form-control form-field"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="login-input password-input-container">
              <label>Password</label>
              {/* <div className="input-group"> */}
              <div className="password-container">
                <input
                  type={visible ? "text" : "password"}
                  placeholder="at least 8 characters"
                  className="form-control form-field form-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span className="password-toggle" onClick={toggle}>
                  {visible ? <AiFillEye /> : <AiFillEyeInvisible />}
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <Link className="forget-password" to="/auth/forgot-password">
                Forgot password?
              </Link>
            </div>

            <div className="login-button">
              <CustomButton
                disabled={loading}
                className="button_check form-control custom-button-center"
                label={loading ? "Waiting..." : "Sign in"}
              />
            </div>
            <div className="divider">
              <span>
                <h5 className="or">Or</h5>
              </span>
            </div>
            {/* <button className="google-btn form-control">
              <svg
                width="25"
                height="25"
                viewBox="0 0 28 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_704_14860)">
                  <path
                    d="M27.7273 14.8223C27.7273 13.8706 27.6501 12.9138 27.4855 11.9775H14.2803V17.3687H21.8423C21.5285 19.1074 20.5202 20.6456 19.0438 21.623V25.121H23.5553C26.2046 22.6827 27.7273 19.0817 27.7273 14.8223Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M14.2803 28.5009C18.0561 28.5009 21.2404 27.2611 23.5605 25.1211L19.049 21.6231C17.7938 22.477 16.1734 22.9606 14.2854 22.9606C10.633 22.9606 7.5362 20.4965 6.42505 17.1836H1.76953V20.7897C4.14616 25.5172 8.98688 28.5009 14.2803 28.5009Z"
                    fill="#34A853"
                  />
                  <path
                    d="M6.4199 17.1837C5.83346 15.4449 5.83346 13.5621 6.4199 11.8234V8.21729H1.76953C-0.216144 12.1732 -0.216144 16.8339 1.76953 20.7898L6.4199 17.1837Z"
                    fill="#FBBC04"
                  />
                  <path
                    d="M14.2803 6.04127C16.2762 6.01041 18.2053 6.76146 19.6508 8.14012L23.6479 4.14305C21.1169 1.76642 17.7578 0.45979 14.2803 0.500943C8.98687 0.500943 4.14616 3.48459 1.76953 8.21728L6.41991 11.8234C7.52591 8.50536 10.6279 6.04127 14.2803 6.04127Z"
                    fill="#EA4335"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_704_14860">
                    <rect
                      width="28"
                      height="28"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              Sign in with Google
            </button> */}
            <button
              className="facebook-btn form-control"
              onClick={handleFaceBookSubmit}
              disabled={loading}
            >
              <span>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 28 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_704_13639)">
                    <path
                      d="M28 14.5C28 6.76801 21.732 0.5 14 0.5C6.26801 0.5 0 6.76801 0 14.5C0 21.4877 5.11957 27.2796 11.8125 28.3299V18.5469H8.25781V14.5H11.8125V11.4156C11.8125 7.90687 13.9027 5.96875 17.1005 5.96875C18.6318 5.96875 20.2344 6.24219 20.2344 6.24219V9.6875H18.4691C16.73 9.6875 16.1875 10.7668 16.1875 11.875V14.5H20.0703L19.4496 18.5469H16.1875V28.3299C22.8804 27.2796 28 21.4877 28 14.5Z"
                      fill="#1877F2"
                    />
                    <path
                      d="M19.4496 18.5469L20.0703 14.5H16.1875V11.875C16.1875 10.7679 16.73 9.6875 18.4691 9.6875H20.2344V6.24219C20.2344 6.24219 18.6323 5.96875 17.1005 5.96875C13.9027 5.96875 11.8125 7.90688 11.8125 11.4156V14.5H8.25781V18.5469H11.8125V28.3299C13.262 28.5567 14.738 28.5567 16.1875 28.3299V18.5469H19.4496Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_704_13639">
                      <rect
                        width="28"
                        height="28"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              Sign in with Facebook
            </button>
          </form>
          <div className="login-question">
            <p>
              Don't you have an account?{" "}
              <Link className="text-primary sign-up" to="/register">
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div
        className="image-section"
        style={{ backgroundImage: backgroundImage }}
      >
        {backgroundImage}
      </div>
    </div>
  );
};

export default Login;
